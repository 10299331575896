<template>
    <div>
        <v-card elevation="0">
            <v-card-title class="justify-center" style="font-weight: 700;font-size: 23px;"></v-card-title>
            <p class="text-center">Email Id: 
            <a href="mailto:customer.support@didnumberprovider.com">customer.support@didnumberprovider.com</a></p>
           <v-card-actions>
        </v-card-actions>
        </v-card>
    </div>
</template>
<script lang="js">
  export default  {
    name: 'Ticket',
    props: [],
    mounted() {
    },
    data() {
      return {
      }
    },
    methods: {
       
    },
    computed: {
    }
}
</script>
<style scoped>
    
</style>